<template>
    <b-container fluid v-if="employee">
        <b-row>
            <b-col cols="12">
                <h2 class="text-center my-4">Uredi radnika</h2>
            </b-col>
            <b-col md="12" class="flex-center">
                <b-form v-on:submit.prevent="updateEmployee" class="w-75">
                    <b-form-group :label-cols-md="4"
                                  label="Ime:"
                                  label-for="name">
                        <b-form-input id="name"
                                      type="text"
                                      size="sm"
                                      name="first_name"
                                      :value="employee.first_name"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Prezime:"
                                  label-for="last_name">
                        <b-form-input id="last_name"
                                      type="text"
                                      size="sm"
                                      name="last_name"
                                      :value="employee.last_name"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Adresa prebivališta:"
                                  label-for="residence_address">
                        <b-form-input id="residence_address"
                                      type="text"
                                      size="sm"
                                      name="residence_address"
                                      :value="employee.residence_address"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Općina prebivališta:"
                                  label-for="residence_township_id">
                        <select :value="employee.residence_township_id"
                                id="residence_township_id"
                                class="form-control form-control-sm"
                                name="residence_township_id"
                                required>
                            <option selected disabled>Izaberite općinu prebivališta</option>
                            <option v-for="township in townships" :value="township.id">
                                {{ township.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Adresa boravišta:"
                                  label-for="current_address">
                        <b-form-input id="current_address"
                                      type="text"
                                      size="sm"
                                      name="current_address"
                                      :value="employee.current_address"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Općina prebivališta:"
                                  label-for="current_township_id">
                        <select :value="employee.current_township_id"
                                id="current_township_id"
                                class="form-control form-control-sm"
                                name="current_township_id"
                                required>
                            <option selected disabled>Izaberite općinu boravišta</option>
                            <option v-for="township in townships" :value="township.id">
                                {{ township.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Telefon:"
                                  label-for="phone">
                        <b-form-input :value="employee.phone"
                                      id="phone"
                                      type="text"
                                      size="sm"
                                      name="phone"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    
                    <b-form-group :label-cols-md="4"
                                  label="JMBG:"
                                  label-for="jmbg">
                        <b-form-input :value="employee.jmbg"
                                      id="jmbg"
                                      type="number"
                                      size="sm"
                                      name="jmbg"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-btn type="submit" variant="primary" class="pull-right">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        data() {
            return {
                employee: null,
                townships: null,
            }
        },
        methods: {
            updateEmployee: function (e) {
                const formData = new FormData(e.target);
                axiOsta.post(api.employees + this.$route.params.id, formData).then( response => {
                        if (response.status === 200) {
                            this.$router.push('/employees/' + response.data.id);
                        }
                    }
                )
            }
        },
        created() {
            axiOsta.get(api.employees + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.employee = response.data;
                }
            });
            axiOsta.get(api.townships).then(response => {
                if (response.status === 200) {
                    this.townships = response.data
                }
            });
        }
    }
</script>
